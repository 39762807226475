import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Layout from './pages/routes/layout';
import Home from './pages/home/home';

import About from './pages/about/about';
import Samples from './pages/samples/samples';
import Templates from './pages/templates/templates';

import Guide from './pages/guide/guide';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="Samples" element={<Samples />} />
      <Route path="Templates" element={<Templates />} />
      <Route path="About Us" element={<About/>} />
      <Route path="Guide" element={<Guide />} />
    </Route>
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
