import React from 'react'
import TopHeader from '../../components/header'
import Collection from './collection'
import AboutUs from '../../components/aboutus'

export default function templates() {
  return (
    <div>
      <TopHeader/>
      <Collection/>
      <AboutUs/>
    </div>
  )
}
