import TopHeader from "../../components/header"
import github from './icon/github.png'
import email from './icon/email.png'
import linkedin from './icon/linkedin.png'
import website from './icon/web.png'
import EndFooter from "../../components/footer"

const people = [
    {
      name: 'Gaurav Aawanke',
      role: 'Founder',
      imageUrl:'https://avatars.githubusercontent.com/u/116870878?v=4',
      githuburl:'https://github.com/iamgauravaawanke',
      linkedinurl:'',
      emailurl:'',
      websiteurl :'',
    },
    {
      name: 'Ruchita Ghate',
      role: 'UI/UX Desinger',
      imageUrl:
        'https://avatars.githubusercontent.com/u/116869738?v=4',
        githuburl:'https://github.com/RuchitaGhate',
        linkedinurl:'',
        emailurl:'',
        websiteurl :'',
    },
    {
      name: 'Prathamesh More',
      role: 'Software Developer',
      imageUrl:'https://avatars.githubusercontent.com/u/91453437?v=4',
      githuburl:'https://github.com/iamprathameshmore',
      linkedinurl:'https://www.linkedin.com/in/iamprathameshmore',
      emailurl:'https://iamprathameshmore07@gmail.com',
      websiteurl :'https://iamprathameshmore.23bg.tech',
    },
    
  ]
  
  export default function About() {
    return (
      <>
      <TopHeader/>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
              suspendisse.
            </p>
          </div>
          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {people.map((person) => (
              <li key={person.name}>
                <div className="flex items-top gap-x-6">
                  <img className="h-16 w-16 rounded pt-2" src={person.imageUrl} alt="" />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                    <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                    
                    <p className=" text-sm leading-2 text-gray-600">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
              suspendisse.
            </p>
            <div className="flex">
                    <a href={person.githuburl} target="_blank" className="flex "><img className="h-6 w-auto p-1"src={github}/>   </a>
                    <a href={person.linkedinurl} target="_blank" className="flex "><img className="h-6 w-auto p-1"src={linkedin}/>   </a>
                    <a href={person.emailurl}  target="_blank" className="flex"><img className="h-6 w-auto p-1"src={email}/>   </a>
                    <a href={person.websiteurl} target="_blank" className="flex "><img className="h-6 w-auto p-1"src={website}/>   </a>
                    </div>
            
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <EndFooter/>
      </>
    )
  }
  